<template>
  <layout-full>
    <router-view />
  </layout-full>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'

export default {
  components: {
    LayoutFull,
  },

  mounted() {
    // eslint-disable-next-line no-nested-ternary
    const referral = this.$route.query.referral
      ? this.$route.query.referral
      : document.referrer && document.referrer !== ''
        ? document.referrer
        : null

    if (referral) {
      this.$cookies.set('referral', referral, 10 * 24 * 60 * 60 * 1000)
    }
  },
}
</script>
